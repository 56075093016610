export const OLD_CURRICULUM_LOCATIONS: { [index: string]: string } = {
    0: "/en/v1/welcome.html",
    1: "/en/v1/ch_u1_intro.html",
    2: "/en/v1/ch_u2_intro.html",
    3: "/en/v1/ch_u3_intro.html",
    4: "/en/v1/teaching-computers-to-listen.html",
    5: "/en/v1/the-earSketch-api.html",
    6: "/en/v1/ch_AdditionalCurricula.html",
    7: "/en/v1/ch_TeacherMaterials.html",
    8: "/en/v1/ch_HourOfCode.html",
    9: "/en/v1/ch_YVIPModule1.html",
    10: "/en/v1/nw_ch_3_esp.html",
    11: "/en/v1/nw_ch_3.html",
    "1-0": "/en/v1/ch_u1_intro.html",
    "1-1": "/en/v1/getting-started.html",
    "1-1-0": "/en/v1/getting-started.html#whylearnprogrammingformusic",
    "1-1-1": "/en/v1/getting-started.html#toolsofthetrade",
    "1-1-2": "/en/v1/getting-started.html#ESworkspace",
    "1-1-3": "/en/v1/getting-started.html#runningascript",
    "1-1-4": "/en/v1/getting-started.html#addingcomments",
    "1-1-5": "/en/v1/getting-started.html#thedawindetail",
    "1-1-6": "/en/v1/getting-started.html#whatisprogramming",
    "1-1-7": "/en/v1/getting-started.html#sectionsofanearsketchscript",
    "1-1-8": "/en/v1/getting-started.html#creatinganewscript",
    "1-1-9": "/en/v1/getting-started.html#composinginearsketch",
    "1-1-10": "/en/v1/getting-started.html#chapter1summary",
    "1-1-11": "/en/v1/getting-started.html#chapter-questions",
    "1-2": "/en/v1/building-blocks.html",
    "1-2-0": "/en/v1/building-blocks.html#rhythm",
    "1-2-1": "/en/v1/building-blocks.html#datatypes",
    "1-2-2": "/en/v1/building-blocks.html#functions",
    "1-2-3": "/en/v1/building-blocks.html#numbers",
    "1-2-4": "/en/v1/building-blocks.html#variables",
    "1-2-5": "/en/v1/building-blocks.html#constants",
    "1-2-6": "/en/v1/building-blocks.html#chapter2summary",
    "1-2-7": "/en/v1/building-blocks.html#chapter-questions",
    "1-3": "/en/v1/debugging-and-documenting.html",
    "1-3-0": "/en/v1/debugging-and-documenting.html#whatisdebugging",
    "1-3-1": "/en/v1/debugging-and-documenting.html#usingtheconsole",
    "1-3-2": "/en/v1/debugging-and-documenting.html#documentingcode",
    "1-3-3": "/en/v1/debugging-and-documenting.html#commonerrors",
    "1-3-4": "/en/v1/debugging-and-documenting.html#debuggingexercise",
    "1-3-5": "/en/v1/debugging-and-documenting.html#chapter3summary",
    "1-3-6": "/en/v1/debugging-and-documenting.html#chapter-questions",
    "1-4": "/en/v1/effects-in-earsketch.html",
    "1-4-0": "/en/v1/effects-in-earsketch.html#effectsinearsketch",
    "1-4-1": "/en/v1/effects-in-earsketch.html#chapter4summary",
    "1-4-2": "/en/v1/effects-in-earsketch.html#chapter-questions",
    "1-5": "/en/v1/effects-and-envelopes.html",
    "1-5-0": "/en/v1/effects-and-envelopes.html#envelopes",
    "1-5-1": "/en/v1/effects-and-envelopes.html#envelopeswithseteffect",
    "1-5-2": "/en/v1/effects-and-envelopes.html#moreeffects",
    "1-5-3": "/en/v1/effects-and-envelopes.html#chapter5summary",
    "1-5-4": "/en/v1/effects-and-envelopes.html#chapter-questions",
    "1-6": "/en/v1/tempo-and-pitch.html",
    "1-6-0": "/en/v1/tempo-and-pitch.html#tempo",
    "1-6-1": "/en/v1/tempo-and-pitch.html#pitch",
    "1-6-2": "/en/v1/tempo-and-pitch.html#transitionstrategies",
    "1-6-3": "/en/v1/tempo-and-pitch.html#chapter6summary",
    "1-6-4": "/en/v1/tempo-and-pitch.html#chapter-questions",
    "1-7": "/en/v1/copyright.html",
    "1-7-0": "/en/v1/copyright.html#whatiscopyright",
    "1-7-1": "/en/v1/copyright.html#copyrightbasics",
    "1-7-2": "/en/v1/copyright.html#copyrightinmusic",
    "1-7-3": "/en/v1/copyright.html#legalissueswithsampling",
    "1-7-4": "/en/v1/copyright.html#fairuse",
    "1-7-5": "/en/v1/copyright.html#licensingandfreeculture",
    "1-7-6": "/en/v1/copyright.html#chapter7summary",
    "1-7-7": "/en/v1/copyright.html#chapter-questions",
    "1-8": "/en/v1/evaluating-correctness.html",
    "1-8-0": "/en/v1/evaluating-correctness.html#selfassessment",
    "1-8-1": "/en/v1/evaluating-correctness.html#performinganevaluation",
    "1-8-2": "/en/v1/evaluating-correctness.html#chapter8summary",
    "1-9": "/en/v1/ch_u1_summary.html",
    "2-0": "/en/v1/ch_u2_intro.html",
    "2-1": "/en/v1/musical-form-and-custom-functions.html",
    "2-1-0": "/en/v1/musical-form-and-custom-functions.html#sectionsandform",
    "2-1-1": "/en/v1/musical-form-and-custom-functions.html#abaform",
    "2-1-2": "/en/v1/musical-form-and-custom-functions.html#customfunctions",
    "2-1-3": "/en/v1/musical-form-and-custom-functions.html#abstraction",
    "2-1-4": "/en/v1/musical-form-and-custom-functions.html#chapter9summary",
    "2-1-5": "/en/v1/musical-form-and-custom-functions.html#chapter-questions",
    "2-2": "/en/v1/recording-and-uploading-sounds.html",
    "2-2-0": "/en/v1/recording-and-uploading-sounds.html#recordinganduploadingsounds",
    "2-2-1": "/en/v1/recording-and-uploading-sounds.html#processesandmemory",
    "2-2-2": "/en/v1/recording-and-uploading-sounds.html#chapter10summary",
    "2-2-3": "/en/v1/recording-and-uploading-sounds.html#chapter-questions",
    "2-3": "/en/v1/making-custom-beats.html",
    "2-3-0": "/en/v1/making-custom-beats.html#strings",
    "2-3-1": "/en/v1/making-custom-beats.html#beatpatternswithstrings",
    "2-3-2": "/en/v1/making-custom-beats.html#makebeat",
    "2-3-3": "/en/v1/making-custom-beats.html#chapter11summary",
    "2-3-4": "/en/v1/making-custom-beats.html#chapter-questions",
    "2-4": "/en/v1/looping.html",
    "2-4-0": "/en/v1/looping.html#theforloop",
    "2-4-1": "/en/v1/looping.html#componentsofaforloop",
    "2-4-2": "/en/v1/looping.html#exampleloop",
    "2-4-3": "/en/v1/looping.html#followingcontrolflow",
    "2-4-4": "/en/v1/looping.html#addingeffectswithloops",
    "2-4-5": "/en/v1/looping.html#automatingeffectswithloops",
    "2-4-6": "/en/v1/looping.html#chapter12summary",
    "2-4-7": "/en/v1/looping.html#chapter-questions",
    "2-5": "/en/v1/string-operations.html",
    "2-5-0": "/en/v1/string-operations.html#returnstatements",
    "2-5-1": "/en/v1/string-operations.html#stringconcatenation",
    "2-5-2": "/en/v1/string-operations.html#substrings",
    "2-5-3": "/en/v1/string-operations.html#chapter13summary",
    "2-5-4": "/en/v1/string-operations.html#chapter-questions",
    "2-6": "/en/v1/musical-repetition.html",
    "2-6-0": "/en/v1/musical-repetition.html#repetitioninmusic",
    "2-6-1": "/en/v1/musical-repetition.html#contrast",
    "2-6-2": "/en/v1/musical-repetition.html#advancedtransitiontechniques",
    "2-6-3": "/en/v1/musical-repetition.html#chapter14summary",
    "2-6-4": "/en/v1/musical-repetition.html#chapter-questions",
    "2-7": "/en/v1/debugging-logic.html",
    "2-7-0": "/en/v1/debugging-logic.html#printingtotheconsole",
    "2-7-1": "/en/v1/debugging-logic.html#thedebuggingprocess",
    "2-7-2": "/en/v1/debugging-logic.html#commonerrors",
    "2-7-3": "/en/v1/debugging-logic.html#moredebuggingexercises",
    "2-7-4": "/en/v1/debugging-logic.html#chapter15summary",
    "2-7-5": "/en/v1/debugging-logic.html#chapter-questions",
    "2-8": "/en/v1/evaluating-correctness-2.html",
    "2-8-0": "/en/v1/evaluating-correctness-2.html#conciseness",
    "2-8-1": "/en/v1/evaluating-correctness-2.html#clarity",
    "2-8-2": "/en/v1/evaluating-correctness-2.html#scriptheaders",
    "2-8-3": "/en/v1/evaluating-correctness-2.html#chapter16summary",
    "2-9": "/en/v1/ch_u2_summary.html",
    "3-0": "/en/v1/ch_u3_intro.html",
    "3-1": "/en/v1/console-input-and-conditionals.html",
    "3-1-0": "/en/v1/console-input-and-conditionals.html#consoleinput",
    "3-1-1": "/en/v1/console-input-and-conditionals.html#booleans",
    "3-1-2": "/en/v1/console-input-and-conditionals.html#conditionalstatements",
    "3-1-3": "/en/v1/console-input-and-conditionals.html#chapter17summary",
    "3-1-4": "/en/v1/console-input-and-conditionals.html#chapter-questions",
    "3-2": "/en/v1/data-structures.html",
    "3-2-0": "/en/v1/data-structures.html#datastructurebasics",
    "3-2-1": "/en/v1/data-structures.html#iteratingthroughadatastructure",
    "3-2-2": "/en/v1/data-structures.html#usingdatastructureswithmakebeat",
    "3-2-3": "/en/v1/data-structures.html#chapter18summary",
    "3-2-4": "/en/v1/data-structures.html#chapter-questions",
    "3-3": "/en/v1/data-structure-operations.html",
    "3-3-0": "/en/v1/data-structure-operations.html#procedure",
    "3-3-1": "/en/v1/data-structure-operations.html#chapter19summary",
    "3-3-2": "/en/v1/data-structure-operations.html#chapter-questions",
    "3-4": "/en/v1/randomness.html",
    "3-4-0": "/en/v1/randomness.html#randomnumbers",
    "3-4-1": "/en/v1/randomness.html#remixingarhythm",
    "3-4-2": "/en/v1/randomness.html#chapter20summary",
    "3-4-3": "/en/v1/randomness.html#chapter-questions",
    "3-5": "/en/v1/evaluating-correctness-3.html",
    "3-5-0": "/en/v1/evaluating-correctness-3.html#peerfeedback",
    "3-5-1": "/en/v1/evaluating-correctness-3.html#criticalresponseprocess",
    "3-5-2": "/en/v1/evaluating-correctness-3.html#collaboration",
    "3-5-3": "/en/v1/evaluating-correctness-3.html#sharinganearsketchscript",
    "3-5-4": "/en/v1/evaluating-correctness-3.html#chapter21summary",
    "3-6": "/en/v1/ch_u3_summary.html",
    "4-0": "/en/v1/teaching-computers-to-listen.html",
    "4-0-0": "/en/v1/teaching-computers-to-listen.html#musicinformationretrieval",
    "4-0-1": "/en/v1/teaching-computers-to-listen.html#analysisfeatures",
    "4-0-2": "/en/v1/teaching-computers-to-listen.html#booleanoperators",
    "4-0-3": "/en/v1/teaching-computers-to-listen.html#chapter22summary",
    "4-0-4": "/en/v1/teaching-computers-to-listen.html#chapter-questions",
    "4-1": "/en/v1/sonification.html",
    "4-1-0": "/en/v1/sonification.html#imagesasdata",
    "4-1-1": "/en/v1/sonification.html#multidimensionalData",
    "4-1-2": "/en/v1/sonification.html#importimage",
    "4-1-3": "/en/v1/sonification.html#nestedloops",
    "4-1-4": "/en/v1/sonification.html#chapter23summary",
    "4-1-5": "/en/v1/sonification.html#chapter-questions",
    "4-2": "/en/v1/sorting.html",
    "4-2-0": "/en/v1/sorting.html#sortingandanalysis",
    "4-2-1": "/en/v1/sorting.html#chapter24summary",
    "4-2-2": "/en/v1/sorting.html#chapter-questions",
    "4-3": "/en/v1/recursion.html",
    "4-3-0": "/en/v1/recursion.html#whatisafractal",
    "4-3-1": "/en/v1/recursion.html#whatisrecursionpt1",
    "4-3-2": "/en/v1/recursion.html#whatisrecursionpt2",
    "4-3-3": "/en/v1/recursion.html#cantorset",
    "4-3-4": "/en/v1/recursion.html#chapter25summary",
    "4-3-5": "/en/v1/recursion.html#chapter-questions",
    "4-4": "/en/v1/intro-to-groovemachine.html",
    "4-4-0": "/en/v1/intro-to-groovemachine.html#_use_groove_machine",
    "4-4-1": "/en/v1/intro-to-groovemachine.html#Overview",
    "4-4-2": "/en/v1/intro-to-groovemachine.html#UnderTheHood",
    "4-4-3": "/en/v1/intro-to-groovemachine.html#AdvancedTopics",
    "5-0": "/en/v1/the-earSketch-api.html",
    "5-1": "/en/v1/every-effect-explained-in-detail.html",
    "5-1-0": "/en/v1/every-effect-explained-in-detail.html#bandpass",
    "5-1-1": "/en/v1/every-effect-explained-in-detail.html#chorus",
    "5-1-2": "/en/v1/every-effect-explained-in-detail.html#compressor",
    "5-1-3": "/en/v1/every-effect-explained-in-detail.html#delay",
    "5-1-4": "/en/v1/every-effect-explained-in-detail.html#distortion",
    "5-1-5": "/en/v1/every-effect-explained-in-detail.html#eq3band",
    "5-1-6": "/en/v1/every-effect-explained-in-detail.html#filter",
    "5-1-7": "/en/v1/every-effect-explained-in-detail.html#flanger",
    "5-1-8": "/en/v1/every-effect-explained-in-detail.html#pan",
    "5-1-9": "/en/v1/every-effect-explained-in-detail.html#phaser",
    "5-1-10": "/en/v1/every-effect-explained-in-detail.html#pitchshift",
    "5-1-11": "/en/v1/every-effect-explained-in-detail.html#reverb",
    "5-1-12": "/en/v1/every-effect-explained-in-detail.html#ringmod",
    "5-1-13": "/en/v1/every-effect-explained-in-detail.html#tremolo",
    "5-1-14": "/en/v1/every-effect-explained-in-detail.html#volume",
    "5-1-15": "/en/v1/every-effect-explained-in-detail.html#wah",
    "5-2": "/en/v1/every-error-explained-in-detail.html",
    "5-2-0": "/en/v1/every-error-explained-in-detail.html#importerror",
    "5-2-1": "/en/v1/every-error-explained-in-detail.html#indentationerror",
    "5-2-2": "/en/v1/every-error-explained-in-detail.html#indexerror",
    "5-2-3": "/en/v1/every-error-explained-in-detail.html#nameerror",
    "5-2-4": "/en/v1/every-error-explained-in-detail.html#parseerror",
    "5-2-5": "/en/v1/every-error-explained-in-detail.html#syntaxerror",
    "5-2-6": "/en/v1/every-error-explained-in-detail.html#typeerror",
    "5-2-7": "/en/v1/every-error-explained-in-detail.html#valueerror",
    "5-3": "/en/v1/analysis-features.html",
    "5-3-0": "/en/v1/analysis-features.html#spectralcentroid",
    "5-3-1": "/en/v1/analysis-features.html#spectrumofalowspectralcentroidvalue",
    "5-3-2": "/en/v1/analysis-features.html#spectrumofahighspectralcentroidvalue",
    "5-3-3": "/en/v1/analysis-features.html#rmsamplitude",
    "5-3-4": "/en/v1/analysis-features.html#timevsamplitudeplotofalowrmsamplitudevalue",
    "5-3-5": "/en/v1/analysis-features.html#timevsamplitudeplotofahighrmsamplitudevalue",
    "5-4": "/en/v1/creating-beats.html",
    "5-4-0": "/en/v1/creating-beats.html#thethreeelementsofapercussionline",
    "5-4-1": "/en/v1/creating-beats.html#somefunkandhiphopbeats",
    "5-4-2": "/en/v1/creating-beats.html#dubstepstylebeats",
    "5-4-3": "/en/v1/creating-beats.html#africanstyledrummingpatterns",
    "5-5": "/en/v1/earsketch-sound-library.html",
    "5-6": "/en/v1/programming-reference.html",
    "8-0": "/en/v1/ch_HourOfCode.html",
    "8-0-0": "/en/v1/ch_HourOfCode.html#WhatIs",
    "8-0-1": "/en/v1/ch_HourOfCode.html#GettingStarted",
    "8-0-2": "/en/v1/ch_HourOfCode.html#RunTheCode",
    "8-0-3": "/en/v1/ch_HourOfCode.html#Clips",
    "8-0-4": "/en/v1/ch_HourOfCode.html#ChangeEnd",
    "8-0-5": "/en/v1/ch_HourOfCode.html#ChangeStart",
    "8-0-6": "/en/v1/ch_HourOfCode.html#StartAndEnd",
    "8-0-7": "/en/v1/ch_HourOfCode.html#DrumFills",
    "8-0-8": "/en/v1/ch_HourOfCode.html#Vars",
    "8-0-9": "/en/v1/ch_HourOfCode.html#BeatStrings",
    "8-0-10": "/en/v1/ch_HourOfCode.html#NewFill",
    "8-0-11": "/en/v1/ch_HourOfCode.html#UserFill",
    "8-0-12": "/en/v1/ch_HourOfCode.html#Comments",
    "8-0-13": "/en/v1/ch_HourOfCode.html#AddSounds",
    "8-0-14": "/en/v1/ch_HourOfCode.html#MakeYourOwn",
    "8-0-15": "/en/v1/ch_HourOfCode.html#GoingFurther",
    "9-0": "/en/v1/ch_YVIPModule1.html",
    "9-0-0": "/en/v1/ch_YVIPModule1.html#MakeBeatsLearnCodePromoteEquity",
    "9-0-1": "/en/v1/ch_YVIPModule1.html#Entrepreneur",
    "9-0-2": "/en/v1/ch_YVIPModule1.html#Messaginginthemusic",
    "9-0-3": "/en/v1/ch_YVIPModule1.html#EquityandEntrepreneurship",
    "9-0-4": "/en/v1/ch_YVIPModule1.html#Remix",
    "9-0-5": "/en/v1/ch_YVIPModule1.html#BeginningtoCodewithEarSketch",
    "9-0-6": "/en/v1/ch_YVIPModule1.html#CreatingaBasicScript",
    "9-0-7": "/en/v1/ch_YVIPModule1.html#Comments",
    "9-0-8": "/en/v1/ch_YVIPModule1.html#Module1Conclusion",
    "9-1": "/en/v1/ch_YVIPModule2.html",
    "9-1-0": "/en/v1/ch_YVIPModule2.html#LayersofInjustice",
    "9-1-1": "/en/v1/ch_YVIPModule2.html#MessagingintheMusic",
    "9-1-2": "/en/v1/ch_YVIPModule2.html#DefiningTypesofRacism",
    "9-1-3": "/en/v1/ch_YVIPModule2.html#LayersofMusic",
    "9-1-4": "/en/v1/ch_YVIPModule2.html#StartingYourSong",
    "9-1-5": "/en/v1/ch_YVIPModule2.html#CreatingYourSoundBank",
    "9-1-6": "/en/v1/ch_YVIPModule2.html#AddingSoundClips",
    "9-2": "/en/v1/ch_YVIPModule3.html",
    "9-2-0": "/en/v1/ch_YVIPModule3.html#_set_your_own_tempo",
    "9-2-1": "/en/v1/ch_YVIPModule3.html#_messaging_in_the_music",
    "9-2-2": "/en/v1/ch_YVIPModule3.html#_entrepreneurs",
    "9-2-3": "/en/v1/ch_YVIPModule3.html#_tempo",
    "9-2-4": "/en/v1/ch_YVIPModule3.html#_change_your_tempo",
    "9-2-5": "/en/v1/ch_YVIPModule3.html#_defining_measures",
    "9-2-6": "/en/v1/ch_YVIPModule3.html#_building_your_16_measure_song",
    "9-2-7": "/en/v1/ch_YVIPModule3.html#_building_your_16_measure_song_part_2",
    "9-2-8": "/en/v1/ch_YVIPModule3.html#_conclusion_module_3",
    "9-3": "/en/v1/ch_YVIPModule4.html",
    "9-3-0": "/en/v1/ch_YVIPModule4.html#_structure_of_a_song",
    "9-3-1": "/en/v1/ch_YVIPModule4.html#_messaging_in_the_music",
    "9-3-2": "/en/v1/ch_YVIPModule4.html#_what_does_it_mean_to_be_an_ally_or_an_activist",
    "9-3-3": "/en/v1/ch_YVIPModule4.html#_song_structure",
    "9-3-4": "/en/v1/ch_YVIPModule4.html#_introduction_to_custom_functions",
    "9-3-5": "/en/v1/ch_YVIPModule4.html#_writing_custom_functions",
    "9-3-6": "/en/v1/ch_YVIPModule4.html#_coding_custom_functions_in_earsketch",
    "9-3-7": "/en/v1/ch_YVIPModule4.html#_conclusion_module_4",
    "9-3-8": "/en/v1/ch_YVIPModule4.html#_bonus_functions_with_staggered_start_and_end_measures",
    "9-4": "/en/v1/ch_YVIP_FinalSubmission.html",
    "9-4-0": "/en/v1/ch_YVIP_FinalSubmission.html#SubmitYourRemix",
    // old spanish filename links from before moving back to english filenames everywhere
    "/es/v2/bienvenidos": "/es/v2/welcome.html",
    "/es/v2/empezamos-con-earsketch": "/es/v2/getting-started.html",
    "/es/v2/tu-primera-cancion": "/es/v2/your-first-song.html",
    "/es/v2/anade-algunos-ritmos": "/es/v2/add-beats.html",
    "/es/v2/ciclos-y-lineas-musicales": "/es/v2/loops-and-layers.html",
    "/es/v2/efectos-y-envelopes": "/es/v2/effects-and-envelopes.html",
    // old yvip landing page
    "/en/v1/ch_YVIPModule1:MakeBeatsLearnCodePromoteEquity": "/en/v1/ch_YVIP_Intro.html",
} as const
