import { fromEntries } from "../../esutils"

// CAI Dialogue Tree
export interface CaiTreeNode {
    id: number, // arbitrary ID used to identify nodes.
    title: string, // User input button label.
    utterance: string, // Message presented by CAI.
    parameters: { genre?: string, instrument?: string, property?: string, propertyValue?: string, changePropertyValue?: string, section?: string, helpTopic?: string, targetSuggestion?: string },
    options: (string | number) [], // node numbers presented as options for users to respond with.
    event?: string [], // trigger specific events in dialogue module.
    dropup?: string, // label for dropup menu (for nodes with large numbers of response options).
    slashCommand?: string, // commands for Wizard of Oz studies.
}

interface HelpItem {
    1: string,
    2: string,
    3: string,
    examplePY: string,
    exampleJS: string,
}

export const CAI_TREE_NODES: { [key: number]: CaiTreeNode } = fromEntries(Object.entries({
    0: {
        title: "",
        utterance: "[GREETING]",
        parameters: {},
        options: [1],
    },
    1: {
        title: "Okay",
        utterance: "let's get started",
        slashCommand: "get_started",
        parameters: {},
        options: [2, 3, 4, 76, 122],
    },
    2: {
        title: "let's get to work",
        utterance: "sounds good. let me know how I can help",
        slashCommand: "do_your_thing",
        parameters: {},
        options: [],
    },
    3: {
        title: "let me add some sounds",
        utterance: "sounds good. go ahead and run it when you're done so i can listen[WAIT|11]",
        slashCommand: "sounds_good_sound",
        parameters: {},
        options: [],
    },
    4: {
        title: "you should suggest sounds",
        utterance: "i think we should start with [sound_rec]",
        slashCommand: "suggest_start_sound",
        parameters: {},
        event: ["soundRequest"],
        options: [5, 6],
    },
    5: {
        title: "ok, i'll add it",
        utterance: "should we add more samples?",
        slashCommand: "suggest_more_samples",
        parameters: {},
        options: [8, 91],
    },
    6: {
        title: "how about something else?",
        utterance: "we could use one of these\n\n[sound_rec]\n[sound_rec]\n[sound_rec]",
        parameters: {},
        slashCommand: "suggest_multi_sound",
        event: ["soundRequest"],
        options: [15, 16, 91],
    },
    7: {
        title: "I want to add a different sound.",
        utterance: "okay, whenever you're ready, you can run it and we can see how it sounds",
        slashCommand: "play_when_ready",
        parameters: {},
        options: [],
    },
    8: {
        title: "yeah, go ahead",
        utterance: "our next move could be [sound_rec][SOUNDWAIT|10]",
        parameters: {},
        slashCommand: "suggest_sound",
        event: ["soundRequest"],
        options: [],
    },
    9: {
        title: "no thanks, i've got the next one",
        utterance: "ok, i'll have a listen next time you run the code",
        parameters: {},
        options: [],
    },
    10: {
        title: "",
        utterance: "that sounds good",
        slashCommand: "sounds_good",
        parameters: {},
        options: [],
    },
    11: {
        title: "",
        utterance: "sounds good. wanna try [sound_rec] next?",
        slashCommand: "sounds_good_suggest_sound",
        parameters: {},
        options: [12, 13],
    },
    12: {
        title: "sounds good to me",
        utterance: "cool. let me see what that sounds like when you run it [WAIT|10]",
        parameters: {},
        options: [],
    },
    13: {
        title: "no thanks",
        utterance: "ok, i'll let you add some stuff and see where we go from there",
        slashCommand: "do_your_thing_2",
        parameters: {},
        options: [],
    },
    14: {
        title: "i have a specific instrument in mind",
        utterance: "sure, which instrument do you want to add?",
        slashCommand: "request_instrument",
        parameters: {},
        dropup: "Instruments",
        options: [37, 38, 39, 40, 41, 42, 43, 44, 45],
    },
    15: {
        title: "ok, i like one of those",
        utterance: "i have another one we could add if you want",
        slashCommand: "suggest_another_sound",
        parameters: {},
        options: [18, 19, 91],
    },
    16: {
        title: "can i see some more ideas?",
        utterance: "what about\n\n[sound_rec]\n[sound_rec]",
        slashCommand: "suggest",
        parameters: {},
        event: ["soundRequest"],
        options: [15, 16, 91],
    },
    17: {
        title: "The third one is best",
        utterance: "Awesome choice! Would you like me to suggest another sound?",
        parameters: {},
        options: [18, 19, 20],
    },
    18: {
        title: "sure",
        utterance: "we could try [sound_rec][SOUNDWAIT|10]",
        parameters: {},
        event: ["soundRequest"],
        options: [19, 6],
    },
    19: {
        title: "no thanks",
        utterance: "no worries",
        parameters: {},
        options: [],
    },
    20: {
        title: "what about something else?",
        utterance: "sure, will these suggestions work?\n[sound_rec]\n[sound_rec]\n[sound_rec]",
        parameters: {},
        options: [15, 16, 17, 91],
    },
    21: {
        title: "The first one works.",
        utterance: "Great! Would you like me to pick a sound?",
        parameters: {},
        options: [18, 19, 20],
    },
    22: {
        title: "I like the second one.",
        utterance: "Great! Would you like me to pick a sound?",
        parameters: {},
        options: [18, 19, 20],
    },
    23: {
        title: "The third one is best.",
        utterance: "Great! Would you like me to pick a sound?",
        parameters: {},
        options: [18, 19, 20],
    },
    24: {
        title: "",
        utterance: "do you need some help with the code?[ERRORWAIT|28]",
        parameters: {},
        options: [25, 26],
    },
    25: {
        title: "no, I want to try and fix it",
        utterance: "[ERRORWAIT|28]",
        parameters: {},
        options: [31],
    },
    26: {
        title: "can you explain the error i'm getting?",
        utterance: "[ERROREXPLAIN][ERRORWAIT|28]",
        parameters: {},
        event: ["errorRequest"],
        options: [],
    },
    27: {
        title: "yes, can you help fix the error?",
        utterance: "[ERRORFIX|29|30]",
        parameters: {},
        options: [],
    },
    28: {
        title: "",
        utterance: "good, it works now",
        parameters: {},
        options: [],
    },
    29: {
        title: "",
        utterance: "i was able to fix the error. let's see if it runs now[ERRORWAIT|28]",
        parameters: {},
        options: [],
    },
    30: {
        title: "",
        utterance: "i'm not sure how to fix this error. i think we should look at the curriculum for help[ERRORWAIT|28]",
        parameters: {},
        options: [],
    },
    31: {
        title: "can you help me?",
        utterance: "sure, what can I help with?",
        parameters: {},
        options: [32],
    },
    32: {
        title: "how do I fix it?",
        utterance: "[ERROREXPLAIN][ERRORWAIT|28]",
        parameters: {},
        options: [],
    },
    33: {
        title: "can you fix the error?",
        utterance: "[ERRORFIX|29|30]",
        parameters: {},
        options: [],
    },
    34: {
        // - BEGIN CODE SUGGESTION TREE
        title: "begin suggestion tree",
        utterance: "[SUGGESTION][RESET_PARAMS]",
        event: ["codeRequest"],
        parameters: {},
        options: [35, 92, 132],
    },
    35: {
        title: "can you explain more?",
        utterance: "[SUGGESTIONEXPLAIN]",
        parameters: {},
        options: [36, 92],
    },
    36: {
        title: "i'm still not 100% on that. do you have an example?",
        utterance: "[SUGGESTIONEXAMPLE]",
        parameters: {},
        options: [],
    },
    37: {
        title: "bass",
        utterance: "how about [sound_rec]?[WAIT|34]",
        parameters: { instrument: "BASS" },
        options: [19, 6, 5],
    },
    38: {
        title: "drums",
        utterance: "how about [sound_rec]?[WAIT|34]",
        parameters: { instrument: "DRUMS" },
        options: [19, 6, 5],
    },
    39: {
        title: "freesound",
        utterance: "how about [sound_rec]?[WAIT|34]",
        parameters: { instrument: "FREESOUND" },
        options: [19, 6, 5],
    },
    40: {
        title: "keyboard",
        utterance: "how about [sound_rec]?[WAIT|34]",
        parameters: { instrument: "KEYBOARD" },
        options: [19, 6, 5],
    },
    41: {
        title: "SFX",
        utterance: "how about [sound_rec]?[WAIT|34]",
        parameters: { instrument: "SFX" },
        options: [19, 6, 5],
    },
    42: {
        title: "strings",
        utterance: "how about [sound_rec]?[WAIT|34]",
        parameters: { instrument: "STRINGS" },
        options: [19, 6, 5],
    },
    43: {
        title: "synth",
        utterance: "how about [sound_rec]?[WAIT|34]",
        parameters: { instrument: "SYNTH" },
        options: [19, 6, 5],
    },
    44: {
        title: "vocals",
        utterance: "how about [sound_rec]?[WAIT|34]",
        parameters: { instrument: "VOCALS" },
        options: [19, 6, 5],
    },
    45: {
        title: "winds",
        utterance: "how about [sound_rec]?[WAIT|34]",
        parameters: { instrument: "WINDS" },
        options: [19, 6, 5],
    },
    46: {
        title: "alt pop",
        utterance: "how about [sound_rec]?[WAIT|34]",
        parameters: { genre: "ALT POP" },
        options: [19, 6, 5],
    },
    47: {
        title: "cinematic score",
        utterance: "how about [sound_rec]?[WAIT|34]",
        parameters: { genre: "CINEMATIC SCORE" },
        options: [19, 6, 5],
    },
    48: {
        title: "dubstep",
        utterance: "how about [sound_rec]?[WAIT|34]",
        parameters: { genre: "DUBSTEP" },
        options: [19, 6, 5],
    },
    49: {
        title: "EDM",
        utterance: "how about [sound_rec]?[WAIT|34]",
        parameters: { genre: "EDM" },
        options: [19, 6, 5],
    },
    50: {
        title: "eight bit",
        utterance: "how about [sound_rec]?[WAIT|34]",
        parameters: { genre: "EIGHTBIT" },
        options: [19, 6, 5],
    },
    51: {
        title: "electro",
        utterance: "how about [sound_rec]?[WAIT|34]",
        parameters: { genre: "ELECTRO" },
        options: [19, 6, 5],
    },
    52: {
        title: "funk",
        utterance: "how about [sound_rec]?[WAIT|34]",
        parameters: { genre: "FUNK" },
        options: [19, 6, 5],
    },
    53: {
        title: "free sound",
        utterance: "how about [sound_rec]?[WAIT|34]",
        parameters: { genre: "FreeSound" },
        options: [19, 6, 5],
    },
    54: {
        title: "gospel",
        utterance: "how about [sound_rec]?[WAIT|34]",
        parameters: { genre: "GOSPEL" },
        options: [19, 6, 5],
    },
    55: {
        title: "hip hop",
        utterance: "how about [sound_rec]?[WAIT|34]",
        parameters: { genre: "HIP HOP" },
        options: [19, 6, 5],
    },
    56: {
        title: "house",
        utterance: "how about [sound_rec]?[WAIT|34]",
        parameters: { genre: "HOUSE" },
        options: [19, 6, 5],
    },
    57: {
        title: "new funk",
        utterance: "how about [sound_rec]?[WAIT|34]",
        parameters: { genre: "NEW FUNK" },
        options: [19, 6, 5],
    },
    58: {
        title: "new hip hop",
        utterance: "how about [sound_rec]?[WAIT|34]",
        parameters: { genre: "NEW HIP HOP" },
        options: [19, 6, 5],
    },
    59: {
        title: "pop",
        utterance: "how about [sound_rec]?[WAIT|34]",
        parameters: { genre: "POP" },
        options: [19, 6, 5],
    },
    60: {
        title: "R & B",
        utterance: "how about [sound_rec]?[WAIT|34]",
        parameters: { genre: "RNB" },
        options: [19, 6, 5],
    },
    61: {
        title: "R & B funk",
        utterance: "how about [sound_rec]?[WAIT|34]",
        parameters: { genre: "RNB FUNK" },
        options: [19, 6, 5],
    },
    62: {
        title: "rock",
        utterance: "how about [sound_rec]?[WAIT|34]",
        parameters: { genre: "ROCK" },
        options: [19, 6, 5],
    },
    63: {
        title: "techno",
        utterance: "how about [sound_rec]?[WAIT|34]",
        parameters: { genre: "TECHNO" },
        options: [19, 6, 5],
    },
    64: {
        title: "trap",
        utterance: "how about [sound_rec]?[WAIT|34]",
        parameters: { genre: "TRAP" },
        options: [19, 6, 5],
    },
    65: {
        title: "UK house",
        utterance: "how about [sound_rec]?[WAIT|34]",
        parameters: { genre: "UK HOUSE" },
        options: [19, 6, 5],
    },
    66: {
        title: "west coast hip hop",
        utterance: "how about [sound_rec]?[WAIT|34]",
        parameters: { genre: "WEST COAST HIP HOP" },
        options: [19, 6, 5],
    },
    67: {
        title: "world percussion",
        utterance: "how about [sound_rec]?[WAIT|34]",
        parameters: { genre: "WORLD PERCUSSION" },
        options: [19, 6, 5],
    },
    68: {
        title: "",
        utterance: "sounds good. thanks for working with me!",
        parameters: {},
        options: [69, 70],
    },
    69: {
        title: "bye!",
        utterance: "see ya",
        parameters: {},
        options: [],
    },
    70: {
        title: "wait, I want to work on it some more",
        utterance: "ok, go ahead",
        parameters: {},
        options: [],
    },
    71: {
        title: "",
        utterance: "what instrument should we add?",
        parameters: {},
        dropup: "Instruments",
        options: [37, 38, 39, 40, 41, 42, 43, 44, 45],
    },
    72: {
        title: "do you want to come up with some sound ideas",
        utterance: "[SECTIONSELECT|73,74|75][RESET_PARAMS]",
        parameters: {},
        options: [73, 74],
    },
    73: {
        title: "no",
        utterance: "we could try [sound_rec][SOUNDWAIT|10]",
        parameters: {},
        event: ["soundRequest"],
        options: [19, 6],
    },
    74: {
        title: "yeah",
        utterance: "which one?",
        parameters: {},
        options: ["SECTIONS|75"],
    },
    75: {
        title: "",
        utterance: "we could try [sound_rec][SOUNDWAIT|10]",
        parameters: {},
        event: ["soundRequest"],
        options: [19, 6],
    },
    76: {
        title: "i have a genre in mind",
        utterance: "cool, what were you thinking?",
        parameters: { property: "genre" },
        options: ["PROPERTYOPTIONS|79"],
    },
    77: {
        title: "i'm not sure. do you have any ideas?",
        utterance: "sure, [SUGGESTPROPERTY]",
        parameters: {},
        options: [82, 84, 85],
    },
    78: {
        title: "",
        utterance: "what were you thinking for [CURRENTPROPERTY]?",
        parameters: {},
        options: ["PROPERTYOPTIONS|79"],
    },
    79: {
        title: "",
        utterance: "[STOREPROPERTY]sounds good. do you want to pick sounds, or should i?",
        parameters: {},
        options: [3, 4, 76, 122],
    },
    80: {
        title: "i have some other thoughts",
        utterance: "ok, what else are you thinking?",
        parameters: {},
        options: ["PROPERTIES|78"],
    },
    81: {
        title: "let's start working",
        utterance: "sounds good. do you want to pick sounds, or should i?",
        parameters: {},
        options: [3, 4],
    },
    82: {
        title: "yeah, i like that",
        utterance: "[STOREPROPERTY]great. do you wanna get started?",
        parameters: {},
        options: [83, 86],
    },
    83: {
        title: "wait, i have an idea about our project",
        utterance: "ok, what were you thinking?",
        parameters: {},
        options: ["PROPERTIES|78"],
    },
    84: {
        title: "i don't know. what about something else?",
        utterance: "[SUGGESTPROPERTY]",
        parameters: {},
        options: [83, 87, 85],
    },
    85: {
        title: "we can just get started",
        utterance: "ok. do you wanna pick some sounds?",
        parameters: {},
        options: [3, 4, 14, 88, 91],
    },
    86: {
        title: "ok",
        utterance: "do you want to start off by picking some sounds?",
        parameters: {},
        options: [3, 4, 14, 88, 91],
    },
    87: {
        title: "sounds good",
        utterance: "[STOREPROPERTY]ok. do you wanna get started?",
        parameters: {},
        options: [83, 86],
    },
    88: {
        title: "i want to tell you what i think we should make",
        utterance: "sure, what were you thinking?",
        parameters: {},
        options: ["PROPERTIES|78"],
    },
    89: {
        title: "i want to change one of our ideas about the project",
        utterance: "which of these do you want to remove from our list?",
        parameters: {},
        options: [94, 95],
    },
    90: {
        title: "",
        utterance: "[CLEARPROPERTY]sounds good. do you have more ideas, or do you want to start working?",
        parameters: {},
        options: [80, 81],
    },
    91: {
        title: "let's start working",
        utterance: "sounds good",
        parameters: {},
        options: [],
    },
    92: {
        title: "ok",
        utterance: "cool, go ahead",
        parameters: {},
        options: [],
    },
    93: {
        title: "How can I add these?",
        utterance: "you can use the [LINK|fitMedia] function",
        parameters: {},
        options: [],
    },
    94: {
        title: "i want to remove one of our ideas",
        utterance: "which of these do you want to remove from our list?",
        parameters: {},
        options: ["CLEARPROPERTYOPTIONS|90"],
    },
    95: {
        title: "i want to change one of our ideas",
        utterance: "which of these do you want to change?",
        parameters: {},
        options: ["[CHANGEPROPERTYOPTIONS|96]"],
    },
    96: {
        title: "",
        utterance: "ok, what do you want to change it out for?",
        parameters: {},
        options: ["[SWAPPROPERTYOPTIONS|97]"],
    },
    97: {
        title: "",
        utterance: "[REPLACEPROPERTY]ok, got it",
        parameters: {},
        options: [81, 100, 94, 101],
    },
    98: {
        title: "i don't think i like that",
        utterance: "no worries",
        parameters: {},
        options: [],
    },
    99: {
        title: "yeah, that sounds good",
        utterance: "ok sweet[SOUNDWAIT|10]",
        parameters: {},
        options: [],
    },
    100: {
        title: "i want to change something else",
        utterance: "what do you want to change?",
        parameters: {},
        options: ["[CHANGEPROPERTYOPTIONS|96]"],
    },
    101: {
        title: "i've got another idea",
        utterance: "what should we add?",
        parameters: {},
        options: ["PROPERTIES|78"],
    },
    102: {
        title: "I have a specific instrument I want to add",
        utterance: "what instrument?",
        slashCommand: "request_instrument_2",
        parameters: {},
        dropup: "Instruments",
        options: [37, 38, 39, 40, 41, 42, 43, 44, 45],
    },
    103: {
        title: "okay",
        utterance: "sounds good",
        parameters: {},
        options: [],
    },
    104: {
        title: "can you walk me through debugging my code?",
        utterance: "sure. do you know where the problem is? the console error message gives us the line number and the error type",
        parameters: {},
        options: [105],
    },
    105: {
        title: "ok, what next?",
        utterance: "we can check the error message for more details",
        parameters: {},
        options: [106, 107, 110],
    },
    106: {
        title: "i'm confused by the error message",
        utterance: "we can also try the explanations in the curriculum that go over all the different error messages in detail",
        parameters: {},
        options: [107, 110],
    },
    107: {
        title: "what should i try next?",
        utterance: "let's make sure we have all the parts we need in the line with the error. are we missing any brackets, parentheses, commas, or colons or semicolons?",
        parameters: {},
        options: [108, 110],
    },
    108: {
        title: "everything is there",
        utterance: "we can also double-check our names and datatypes. we might have misspelled something, or put arguments in the wrong order",
        parameters: {},
        options: [109, 110],
    },
    109: {
        title: "ok, i did that",
        utterance: "okay. can you try going line-by-line and telling yourself what each line does? that can help us find problems",
        parameters: {},
        options: [110],
    },
    110: {
        title: "i'm still stuck",
        utterance: "[ERROREXPLAIN][ERRORWAIT|28]",
        parameters: {},
        options: [],
    },
    111: {
        title: "can you help me code something?",
        utterance: "sure, what do you want help with?",
        parameters: {},
        options: [112, "[PY:115|JS:116]", 118, 119, 120, 121, 134, 135, 136],
    },
    112: {
        title: "a fitMedia() statement",
        utterance: "[STEP1]",
        parameters: { helpTopic: "a fitMedia statement" },
        options: [113, 131],
    },
    113: {
        title: "what next?",
        utterance: "[STEP2]",
        parameters: { helpTopic: "" }, // keeps dialogue.ts from prematurely hiding help options
        options: [114, 131],
    },
    114: {
        title: "what do i do now?",
        utterance: "[STEP3]",
        parameters: { helpTopic: "" }, // keeps dialogue.ts from prematurely hiding help options
        options: [131],
    },
    115: {
        title: "a for loop with range()",
        utterance: "[STEP1]",
        parameters: { helpTopic: "a for loop with range()" },
        options: [113, 131],
    },
    116: {
        title: "a for loop",
        utterance: "[STEP1]",
        parameters: { helpTopic: "a for loop" },
        options: [113, 131],
    },
    117: {
        title: "a while loop",
        utterance: "[STEP1]",
        parameters: { helpTopic: "a while loop" },
        options: [113, 131],
    },
    118: {
        title: "a custom function",
        utterance: "[STEP1]",
        parameters: { helpTopic: "a custom function" },
        options: [113, 131],
    },
    119: {
        title: "a makeBeat() call",
        utterance: "[STEP1]",
        parameters: { helpTopic: "a makeBeat() call" },
        options: [113, 131],
    },
    120: {
        title: "a conditional statement",
        utterance: "[STEP1]",
        parameters: { helpTopic: "a conditional statement" },
        options: [113, 131],
    },
    121: {
        title: "taking user input",
        utterance: "[STEP1]",
        parameters: { helpTopic: "taking user input" },
        options: [113, 131],
    },
    122: {
        title: "i have an instrument i want to use",
        utterance: "cool, what were you thinking?",
        parameters: { property: "instrument" },
        options: ["PROPERTYOPTIONS|79"],
    },
    123: {
        title: "ok, i'm done with this",
        utterance: "sounds good",
        parameters: {},
        options: [],
    },
    124: {
        title: "i need help with the EarSketch site",
        utterance: "sure, what do you need help with?",
        parameters: {},
        options: [125, 126, 127],
    },
    125: {
        title: "retrieve my previous code?",
        utterance: "[HIGHLIGHTHISTORY]",
        parameters: {},
        options: [129, 130],
    },
    126: {
        title: "find something in the curriculum?",
        utterance: "[HIGHLIGHTSEARCHCURR]",
        parameters: {},
        options: [129, 130],
    },
    127: {
        title: "look for functions in the API?",
        utterance: "[HIGHLIGHTSEARCHAPI]",
        parameters: {},
        options: [129, 130],
    },
    128: {
        title: "i found what I was looking for",
        utterance: "ok, go ahead[CLEARHIGHLIGHT].",
        parameters: {},
        options: [],
    },
    129: {
        title: "never mind.",
        utterance: "ok.[CLEARHIGHLIGHT]",
        parameters: {},
        options: [],
    },
    130: {
        title: "found it.",
        utterance: "ok, go ahead.[CLEARHIGHLIGHT]",
        parameters: {},
        options: [],
    },
    131: {

        title: "can you show me an example?",
        utterance: "[HELPEXAMPLE]",
        parameters: { helpTopic: "" }, // keeps dialogue.ts from prematurely hiding help options
        options: [],
    },
    132: {
        // "reset" node for escaping suggestions
        title: "no thanks",
        utterance: "[CLEARSUGGESTION]",
        parameters: { },
        options: [],
    },
    133: {
        // "reset" node for escaping suggestions
        title: "",
        utterance: "make sure to run your code when you're done editing it so i can listen to our song",
        parameters: { },
        options: [],
    },
    134: {
        title: "taking user input",
        utterance: "[STEP1]",
        parameters: { helpTopic: "taking user input" },
        options: [113, 131],
    },
    135: {
        title: "a setEffect() call",
        utterance: "[STEP1]",
        parameters: { helpTopic: "a setEffect() call" },
        options: [113, 131],
    },
    136: {
        title: "a setTempo() call",
        utterance: "[STEP1]",
        parameters: { helpTopic: "a setTempo() call" },
        options: [113, 131],
    },
    137: {
        title: "",
        utterance: "i think we have some overlapping sounds in our project. we should clear those by moving some of them to a different measure or track",
        parameters: {},
        options: [],
    },
}).map(([id, node]) => [id, { id: +id, ...node }]))

// Starting indices of CAI_TREE_NODES by conversation topic.
export const CAI_TREES: { [key: string]: number } = { "Chat with CAI": 0, error: 26, begin: 1, sound_select: 72, suggest: 34, wrapup: 68, selectinstr: 71, properties: 88, debug: 104, help: 111, genre: 76, promptRun: 133 }

// error explanations for CAI to use, based upon error type
export const CAI_ERRORS: { [key: string]: string } = {
    ParseError: "looks like you've got a [LINK|parse error]. I think we might be missing something",
    ImportError: "something's not [LINK|importing] right. do we have the right package name up top?",
    IndentationError: "looks like one of our lines isn't [LINK|indented] right",
    IndexError: "i think this means we're trying to use an [LINK|index] that doesn't exist",
    NameError: "i think we're trying to use a variable or function that we haven't defined, or maybe we misspelled a [LINK|name]",
    SyntaxError: "we have a [LINK|syntax error], which might mean that we're using the wrong operator",
    TypeError: "it's saying [LINK|type error], which means that we put in the wrong kind of data, or we're missing something",
    ValueError: "i think something is wrong with one of our [LINK|function arguments]",
    ServerError: "this is an issue with the ES server, and not with your code. we might have to make some changes",
}

export const CAI_ERRORS_NEW: { [key: string]: { [key: string]: string } } = {
    name: {
        "typo:": "looks like we might have a typo in [NAME]",
        "unrecognized:": "it's saying [NAME] doesn't exist. are you sure we have a variable or function with that name?",
    },
    syntax: {
        "mismatched curly braces": "something's up with our curly braces. there's an extra or a missing one somewhere",
    },
    "for loop": {
        "missing opening parenthesis": "we need to make sure we've got an opening parenthesis in our for loop",
        "missing closing parenthesis": "looks like we forgot a close parenthesis in the for loop",
        "invalid loop declaration": "something is wrong in the code where we first declare our for loop",
        "invalid loop condition": "our loop condition doesn't look quite right; let's make sure it works ou to a Boolean value",
        "missing body": "we need to put something inside of our for loop, or our code won't run correctly",
        "missing for": "looks like we're missing the 'for' keyword in our loop",
        "missing iterator name": "we need to name the iterator in our for loop",
        "missing in": "remember, we need to use the 'in' keyword in our for loop",
        "missing colon": "looks like our for loop declaration is missing a colon at the end",
        "range missing parentheses": "since range() is a function, we need to use parentheses after it",
        "incorrect number of range arguments": "range() takes between one and three arguments. let's make sure we're within that",
        "non-numeric range argument": "range() only takes numerical arguments",
        "invalid iterable": "i think we might be trying to iterate over an object that we can't iterate over. let's try something else",
    },
    function: {
        "missing function keyword": "our function definitions need to have the 'function' keyword right at the start, and I think we have it missing somewhere",
        "invalid function name": "we can't use that as our function name. we'll have to pick a different one",
        "missing opening parenthesis": "our function declaration needs a set of parentheses after the function name",
        "missing closing parenthesis": "looks like we've missed a close parenthesis in our function declaration",
        "missing closing curly brace": "our closing curly brace in our function declaration might have been deleted by accident",
        "parameters missing commas": "oh, i think we need commas in between our function parameters",
        "missing body": "if we don't have anything in our function, our code won't run right",
        "missing def": "i think we're missing the 'def' keyword in our function declaration",
        "missing parentheses": "our function declaration needs a set of parentheses after the function name",
        "missing function name": "i think we may have forgotted to give our function a name",
        "missing colon": "we'll need a colon after our first line declaring our function",
        "value instead of parameter": "in the function declaration, instead of values, we need to name the parameters the function takes in. that way, we can refer to them inside of the function by that name",
    },
    parentheses: {
        mismatch: "i think we have some mismatched parentheses",
    },
    import: {
        "missing import": "i think the 'from earsketch impot *' statement got deleted. let's make sure that's in there",
    },
    "function call": {
        "missing parentheses": "we need to include parentheses with our function call",
        "parentheses mismatch": "i think we've got mismatched parentheses in our function call. can you make sure we've got the same number of open and close parens?",
        "extra words": "we've got some extra words we don't need around our function call",
        "too few arguments": "oh, i think we don't have as many arguments as we're supposed to",
        "too many arguments": "we might have too many arguments for the function we're calling",
    },
    "while loop": {
        "missing while keyword": "i think you're trying to add a while loop, so we'll need to add the 'while' keyword",
        "missing parentheses": "while loops need parentheses after the word while, so let's make sure we have those",
        "parentheses mismatch": "i think we have some issues with the parentheses. let's make sure we've got them all matched up",
        "missing colon": "our while loop declaration needs a colon after it, and i think that's what's missing",
        "missing body": "oh, the loop is empty. the code won't run if there's nothing in our loop",
    },
    conditional: {
        "missing parentheses": "i think our conditional statement is missing a set of parentheses",
        "parentheses mismatch": "i think we have different numbers of open and close parentheses. we should check on that",
        "missing colon": "we'll need a colon after our if statement",
        "missing body": "we need to have a body inside of our if statement, or the code won't run",
        "misindented else": "our else statement needs to be indented to the same level as the if statement",
        "missing opening parenthesis": "we'll need a set of parentheses in our conditional",
        "missing closing parenthesis": "oh, i think we left off a closing parenthesis",
        "invalid condition": "the condition in our if statement isn't a valid boolean value, so we'll have to use something else",
    },
    fitMedia: {
        miscapitalization: "oh, fitMedia needs a lowercase f and a capital M",
        "missing parentheses": "we've gotta have parentheses in our fitMedia call",
        "too many arguments": "fitMedia needs 4 arguments, so i think we've got too many",
        "too few arguments": "i think our fitMedia call needs more arguments",
        "arg 1 wrong type": "our first argument needs to be a valid sound sample, and i don't know if it is",
        "arg 2 wrong type": "our second argument needs to be an integer value, since that's the track number for the sound",
        "arg 3 wrong type": "our third argument should be a number, because it's supposed to represent the start measure",
        "arg 4 wrong type": "we need our last fitMedia argument to be a number",
        "track number not integer": "our track number needs to be an integer so earsketch knows where to  put the sound",
        "invalid track number": "i'm not sure we have the right number as our track number",
        "invalid start measure": "looks like our start measure isn't right. it needs to be bigger than 1",
        "invalid end measure": "i think something's up with our end measure in our fitMedia call",
        "backwards start/end": "oh, i think our start and end measure numbers are backwards",
    },
}

export const CAI_HELP_ITEMS: { [key: string]: HelpItem } = {
    "a fitMedia statement": {
        1: "let's start by putting in the function name and our parentheses: [LINK|fitMedia]()",
        2: "then, we need to put in our arguments. we'll need a sample name, a track number, a start measure, and an end measure. this information can be found in the API pane on the left hand side of the page too",
        3: "next, let's make sure all our arguments are there and separated by commas, and we have anything else (like a semicolon) we need on the line",
        examplePY: "[LINK|fitMedia](HIPHOP_SYNTHPLUCKLEAD_005, 1, 1, 5)",
        exampleJS: "[LINK|fitMedia](HIPHOP_SYNTHPLUCKLEAD_005, 1, 1, 5);",
    },
    "a for loop with range()": {
        1: "we need to start by declaring the [LINK|loop]. something like for i in range(start, end):",
        2: "then we put in the body of the [LINK|loop]. don't forget to indent everything that's supposed to be inside the loop",
        3: "we can use i (or whatever we named our loop variable) inside the loop",
        examplePY: "for example, this code:\n\n[LINK|for] measure in range(1, 5):\n    [LINK|makeBeat](OS_SNARE03, 1, measure, \"0---0---0-0-0---\")\n\nputs the same beat in measures 1-5 on track 1",
        exampleJS: "for example, this code:\n\n[LINK|for] (measure = 1; measure < 6; measure++) {\n    [LINK|makeBeat](OS_SNARE03, 1, measure, \"0---0---0-0-0---\");\n}\n\nputs the same beat in measures 1-5 on track 1",
    },
    "a for loop": {
        1: "let's start by declaring the [LINK|loop]. we need something like this:\nfor(int i = start; i < end; i++){\n\n}",
        2: "then we need the body of the [LINK|loop]. everything inside the curly braces will be looped through.",
        3: "we can use i (or whatever we named our loop variable) inside the loop",
        examplePY: "for example, this code:\n\n[LINK|for] measure in range(1, 5):\n    [LINK|makeBeat](OS_SNARE03, 1, measure, \"0---0---0-0-0---\")\n\nputs the same beat in measures 1-5 on track 1",
        exampleJS: "for example, this code:\n\n[LINK|for] (measure = 1; measure < 6; measure++) {\n    [LINK|makeBeat](OS_SNARE03, 1, measure, \"0---0---0-0-0---\");\n}\n\nputs the same beat in measures 1-5 on track 1",
    },
    "a custom function": {
        1: "we need to start by declaring our [LINK|function] and its name",
        2: "then we can include any [LINK|parameters] we want to include",
        3: "finally, we can add the body of our function",
        examplePY: "[LINK|def] sectionA(start, end):\n    fitMedia(melody2, 1, start, end)\n    fitMedia(brass1, 2, start, end)\n    setEffect(2, VOLUME, GAIN, -20, start, -10, end)\nsectionA(1,5)",
        exampleJS: "[LINK|function] sectionA(start, end) {\n    fitMedia(melody2, 1, start, end);\n    fitMedia(brass1, 2, start, end);\n    setEffect(2, VOLUME, GAIN, -20, start, -10, end);\n}\nsectionA(1,5);",
    },
    "a makeBeat() call": {
        1: "let's start by putting in the call to [LINK|makeBeat]()",
        2: "now let's fill in the arguments. we can use either the simple or advanced [LINK|makeBeat]",
        3: "if we're using the simple makeBeat, our beat string should have 0s, and plus and minus signs. if we're using the advanced makeBeat, we can [LINK|index] a list of sounds\n\n a simple beat string should use the characters \"0\", \"+\" or \"-\" and should be 4, 8 , or 16 characters long. each character corresponds to a note. 0 starts playing the sound clip. + extends the audio clip into the next sub-beat and needs to follow a 0 or +. - is a rest, meaning silence        ",
        examplePY: "something like \n\n[LINK|makeBeat](DUBSTEP_FILTERCHORD_002, 1, 1, \"-00-00+++00--0-0\")\n    [LINK|makeBeat](OS_CLOSEDHAT01, 2, 1, \"0--0--000--00-0-\")",
        exampleJS: "something like \n\n[LINK|makeBeat](DUBSTEP_FILTERCHORD_002, 1, 1, \"-00-00+++00--0-0\");\n    [LINK|makeBeat](OS_CLOSEDHAT01, 2, 1, \"0--0--000--00-0-\");",
    },
    "a conditional statement": {
        1: "first we'll need an [LINK|if statement] and a condition. we should make sure the condition works out to be TRUE or FALSE",
        2: "then we can add some code that will run if the condition is TRUE",
        3: "we can also add else-if or else statements too if we want other code to run if our first condition is FALSE",
        examplePY: "a = readInput(\"Do you like hip-hop music? Yes/No.\")\n        \n\n[LINK|if] (a == \"yes\" or a == \"Yes\" or a == \"YES\"):\n      print(\"Hip-hop it is!\") \n    [LINK|fitMedia](YG_NEW_HIP_HOP_ARP_1, 1, 1, 9)        \nelse:    \n     print(\"Ok, here is some funk.\")    \n [LINK|fitMedia](YG_NEW_FUNK_ELECTRIC_PIANO_4, 1, 1, 9)",
        exampleJS: "[LINK|var] a = readInput(\"Do you like hip-hop music? Yes/No.\");\n        \n\n[LINK|if] (a == \"yes\" or a == \"Yes\" or a == \"YES\") {\n      println(\"Hip-hop it is!\"); \n    [LINK|fitMedia](YG_NEW_HIP_HOP_ARP_1, 1, 1, 9);\n} else {    \n     println(\"Ok, here is some funk.\");   \n [LINK|fitMedia](YG_NEW_FUNK_ELECTRIC_PIANO_4, 1, 1, 9);\n}",
    },
    "taking user input": {
        1: "to take [LINK|console input], we'll need to call readInput(). for the function arguments, we can put a string that will label the pop-up where the user puts in their input",
        2: "we can assign the value returned by the function to a [LINK|variable]",
        3: "then, we can use that variable, which contains a string with the user's input, in our code. if we want to use it as a number, we'll have to cast it first",
        examplePY: "answer = [LINK|readInput](\"What tempo would you like for your music? Choose a number between 45 and 220\")\n        # converting to a number\n       tempo = int(answer)\n\n       # setting the tempo\n       [LINK|setTempo](tempo)",
        exampleJS: "[LINK|var] answer = [LINK|readInput](\"What tempo would you like for your music? Choose a number between 45 and 220\");\n        // converting to a number\n       [LINK|var] tempo = Number(answer);\n\n       // setting the tempo\n       [LINK|setTempo](tempo);",
    },
    "a setEffect() call": {
        1: "let's start by putting in the call to [LINK|setEffect]",
        2: "both the simple and advanced [LINK|setEffect] take the track number, effect type, and effect parameter as the first three arguments. you can look these up in the curriculum",
        3: "then we need the value. we can put in one value for the simple setEffect, or a start value and start measure and end value and end measure if we want to change the effect over time",
        examplePY: "something like\n[LINK|setEffect](1, VOLUME, GAIN, 2)",
        exampleJS: "something like\n[LINK|setEffect](1, VOLUME, GAIN, 2);",
    },
    "a setTempo() call": {
        1: "first we'll need to put in the call to [LINK|setTempo]() itself",
        2: "then we need to pass it a number value between 45 and 220",
        3: "we need to make sure this is a number. if we're taking this in from the user, we will need to cast the string to a number",
        examplePY: "for example\n[LINK|setTempo](120)\nsets the tempo to 120",
        exampleJS: "for example\n[LINK|setTempo](120);\n sets the tempo to 120",
    },
}
